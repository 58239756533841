<template>
  <div class="column is-narrow-mobile">
    <div class="card">
      <header class="card-header is-flex is-flex--space-between">
        <div>
          <p class="card-header-title is-size-5 has-text-grey">{{ $t('users.title') }}</p>
        </div>
        <div class="is-flex is-flex--center">
          <div class="is-flex is-flex--space-between">
            <app-input-search :has-filter="false" @on-text-enter="searchFilteredUsers"></app-input-search>
          </div>
          <a class="card-header-icon" aria-label="more options">
            <router-link :to="{ name: 'userCreate' }" class="button is-accent is-small">
              <span class="icon">
                <i class="fas fa-plus"></i>
              </span>
              <span>{{ $t('forms.buttons.addUser') }}</span>
            </router-link>
          </a>
        </div>
        <!-- <a class="card-header-icon" aria-label="more options">
          <a class="button is-small">
            <span class="icon">
              <i class="fas fa-download"></i>
            </span>
          </a>
        </a>-->
      </header>
      <div class="card-content">
        <b-table
          :loading="requestUsers"
          :total="totalUsers"
          :data="users.length ? users : []"
          :per-page="usersPagination.perPage"
          backend-pagination
          paginated
          pagination-size="is-small"
          narrowed
          striped
          @page-change="triggerAsyncPageChange"
        >
          <template slot-scope="props">
            <b-table-column field="id" label="ID" width="40" numeric
              >{{ props.row.id }}
              <a class="has-text-info" v-if="copiedText === props.row.auth0UserId" @click="copyAuth0Id(props.row.auth0UserId)">
                <b-tooltip label="copied!" position="is-top" type="is-info" size="is-small" animated square>
                  <b-icon class="has-padding" pack="fas" icon="check" size="is-small"></b-icon>
                </b-tooltip>
              </a>
              <a class="has-text-info" v-else @click="copyAuth0Id(props.row.auth0UserId)">
                <b-tooltip :label="props.row.auth0UserId" position="is-top" type="is-info" size="is-small" animated square>
                  <b-icon class="has-padding" pack="fas" icon="user" size="is-small"></b-icon>
                </b-tooltip>
              </a>
            </b-table-column>
            <b-table-column field="image" label="Avatar" width="48" centered>
              <figure v-if="props.row.image" class="is-flex is-flex--center">
                <p class="image is-32x32">
                  <img :src="getUrlImage(props.row.image)" class="is-rounded" />
                </p>
              </figure>
              <span v-else class="icon is-medium">
                <span class="fa-stack">
                  <i class="fas fa-circle fa-stack-2x"></i>
                  <i class="fas fa-user fa-stack-1x fa-inverse"></i>
                </span>
              </span>
            </b-table-column>
            <b-table-column field="name" label="Organización" width="100" centered>
              <p>
                <span :class="props.row.organization ? 'is-info' : 'is-danger'" class="tag is-info">{{
                  props.row.organization.organization_name || 'Sin Organización'
                }}</span>
              </p>
              <template v-if="props.row.organizations.length > 0">
                <p
                  v-for="linkedOrganization in getTrimmedOrganizations(props.row.organization, props.row.organizations)"
                  :key="linkedOrganization.id"
                >
                  <span class="tag is-grey">{{ linkedOrganization.organization_name }}</span>
                </p>
              </template>
            </b-table-column>
            <b-table-column field="email" label="Username" width="100" centered>{{ props.row.username }}</b-table-column>
            <!-- <b-table-column field="username" label="Username">
              {{ props.row.username }}
            </b-table-column>-->
            <b-table-column field="created" label="Creación" centered>
              <span class="tag is-info">{{ new Date(props.row.created).toLocaleDateString() }}</span>
            </b-table-column>
            <b-table-column field="status" label="Estado" centered>
              <span :class="props.row.enabled ? 'is-success' : 'is-danger'" class="tag is-success">{{
                props.row.enabled ? 'Activo' : 'Inactivo'
              }}</span>
            </b-table-column>
            <b-table-column v-if="!!props.row.enabled" field="actions" label="Acciones" centered>
              <a class="has-text-info" @click="goToUserEdition(props.row.auth0UserId)">
                <b-tooltip :label="$t('tables.edit')" position="is-top" type="is-info" size="is-small" animated square>
                  <b-icon class="has-padding" pack="fas" icon="edit" size="is-small"></b-icon>
                </b-tooltip>
              </a>
              <a class="has-text-danger" @click="showConfirmationModal(props.row.id, true)">
                <b-tooltip :label="$t('tables.remove')" position="is-top" type="is-danger" size="is-small" animated square>
                  <b-icon class="has-padding" pack="fas" icon="trash" size="is-small"></b-icon>
                </b-tooltip>
              </a>
            </b-table-column>
            <b-table-column v-else field="actions" label="Acciones" centered>
              <a class="has-text-success" @click="showConfirmationModal(props.row.id, false)">
                <b-tooltip :label="$t('tables.add')" position="is-top" type="is-success" size="is-small" animated square>
                  <b-icon class="has-padding" pack="fas" icon="power-off" size="is-small"></b-icon>
                </b-tooltip>
              </a>
            </b-table-column>
          </template>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p v-show="!shortQuerySearchError && !requestUserError && !requestUsers">
                  <b-icon pack="fas" icon="exclamation" size="is-large"></b-icon>
                </p>
                <p v-show="!shortQuerySearchError && !requestUserError && !requestUsers">{{ $t('warnings.accountNoData') }}</p>
              </div>
              <div class="content has-text-grey has-text-centered">
                <p v-show="shortQuerySearchError">
                  <b-icon pack="fas" icon="exclamation" size="is-large"></b-icon>
                </p>
                <p v-show="shortQuerySearchError">{{ $t('warnings.shortQuerySearchError') }}</p>
              </div>
              <div v-show="requestUserError" class="content has-text-danger has-text-centered">
                <p>
                  <span class="icon is-large">
                    <i class="far fa-frown fa-3x"></i>
                  </span>
                </p>
                <p>{{ $t('errors.requestError') }}</p>
              </div>
            </section>
          </template>
        </b-table>
      </div>
    </div>
    <b-modal :active.sync="confirmationModalActive" :canCancel="['x', 'outside']" class="has-modal-card">
      <app-modal-template ref="confirmationModal" :has-closable-button="true">
        <template slot="title">
          <p class="modal-card-title">{{ removeOperation ? 'Borrar' : 'Habilitar' }} usuario</p>
        </template>
        <template slot="content">
          <p class="modal-card-title">¿Esta seguro que quiere {{ removeOperation ? 'eliminar' : 'habilitar' }} este usuario?</p>
        </template>
        <template slot="buttons">
          <button v-if="removeOperation" class="button is-accent" @click="onRemoveUser" :class="{ 'is-loading': showLoader }">
            <span class="icon">
              <i class="fa fa-trash"></i>
            </span>
            <span>Borrar</span>
          </button>
          <button v-else class="button is-accent" @click="onAddUser" :class="{ 'is-loading': showLoader }">
            <span class="icon">
              <i class="fa fa-power-off"></i>
            </span>
            <span>Habilitar</span>
          </button>
        </template>
      </app-modal-template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import InputSearch from '@/common/widgets/search/InputSearch'
import ModalTemplate from '@/common/widgets/modal/ModalTemplate'

export default {
  name: 'userTable',
  components: {
    'app-input-search': InputSearch,
    appModalTemplate: ModalTemplate
  },
  data() {
    return {
      UserId: null,
      fetchSuccess: false,
      confirmationModalActive: false,
      removeOperation: null,
      copiedText: ''
    }
  },
  computed: {
    ...mapGetters([
      'users',
      'usersPagination',
      'totalUsers',
      'requestUsers',
      'requestUserError',
      'shortQuerySearchError',
      'tokenValid',
      'showLoader'
    ])
  },
  watch: {
    tokenValid(value) {
      if (!value) {
        this.$router.push({ name: 'login' })
      }
    }
  },
  created() {
    if (this.$route.query.hasOwnProperty('q')) {
      this.searchFilteredUsers({ textToSearch: this.$route.query.q })
    } else {
      this.getUsers({ page: 1, size: process.env.VUE_APP_PAGE_SIZE })
    }
    // this.getUsers({ page: 1, size: 0 })
    // this.getUsers({ page: 1, size: 20 })
  },
  methods: {
    ...mapActions(['getUsers', 'removeUser', 'addUser']),
    getUrlImage(imagePath) {
      if (imagePath) {
        let urlBase64Image = `${process.env.VUE_APP_CDN_URL}/images/${imagePath}`
        return urlBase64Image
      }
    },
    getTrimmedOrganizations(currentOrganization, arrayOrganizations) {
      return arrayOrganizations.filter((organization) => currentOrganization.organization_id !== organization.organization_id).slice(0, 2)
    },
    goToUserEdition(idUser) {
      this.$router.push({ name: 'userEdit', params: { id: `${idUser}` } })
    },
    searchFilteredUsers({ textToSearch }) {
      let pageSize = process.env.VUE_APP_PAGE_SIZE
      this.getUsers({ page: 1, size: pageSize, q: textToSearch })
    },
    triggerAsyncPageChange(page) {
      let pageSize = process.env.VUE_APP_PAGE_SIZE
      if (this.$route.query.hasOwnProperty('q')) {
        this.getUsers({ page: page, size: pageSize, q: this.$route.query.q })
      } else {
        this.getUsers({ page: page, size: pageSize })
      }
    },
    showConfirmationModal(id, removeOperation) {
      this.confirmationModalActive = !this.confirmationModalActive
      this.UserId = id
      this.removeOperation = removeOperation
    },
    async onRemoveUser() {
      if (this.confirmationModalActive) {
        await this.removeUser(this.UserId)
      }
      this.closeModal()
    },
    async onAddUser() {
      if (this.confirmationModalActive) {
        await this.addUser(this.UserId)
      }
      this.closeModal()
    },
    closeModal() {
      let pageSize = process.env.VUE_APP_PAGE_SIZE
      this.$refs.confirmationModal.closeModal()
      if (this.$route.query.hasOwnProperty('q')) {
        this.getUsers({ page: 1, size: pageSize, q: this.$route.query.q })
      } else {
        this.getUsers({ page: 1, size: pageSize })
      }
    },
    copyAuth0Id(auth0Id) {
      var TempText = document.createElement('input')
      TempText.value = auth0Id
      this.copiedText = auth0Id
      document.body.appendChild(TempText)
      TempText.select()
      document.execCommand('copy')
      document.body.removeChild(TempText)
    }
  }
}
</script>
<style lang="scss" scoped></style>
