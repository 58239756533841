<template>
  <div>
    <!-- Start Button Permission  -->
    <b-field grouped position="is-right">
      <p class="control">
        <a
          :class="[{ 'is-loading': fetchingAllPermissions || fetchingAllRoles }, 'is-accent']"
          class="button is-rounded is-focused is-small"
          @click="showPermissionsModal()"
        >
          <span class="icon">
            <i class="fas fa-key"></i>
          </span>
          <span v-if="!!allPermissions.length">Establecer Permisos</span>
          <span v-else>No hay Permisos</span>
        </a>
      </p>
    </b-field>
    <!-- End Button Permission -->

    <!-- Modal Permission -->
    <b-modal :active.sync="showModalTrigger" :canCancel="['x', 'outside']" class="has-modal-card">
      <app-modal-template ref="permissionModal" :has-closable-button="true" style="width: auto">
        <template slot="title">
          <p class="modal-card-title" style="text-align: center">Permisos de Aplicaciones</p>
        </template>
        <template slot="content">
          <app-user-permission-select :selectedUserPermissions.sync="localSelectedUserPermissions"></app-user-permission-select>
        </template>
        <template slot="buttons">
          <button class="button is-accent" @click="onSetUserPermissions">
            <span class="icon">
              <i class="far fa-save"></i>
            </span>
            <span>Guardar</span>
          </button>
        </template>
      </app-modal-template>
    </b-modal>
    <!-- End Modal Permission -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ModalTemplate from '@/common/widgets/modal/ModalTemplate'
import UserPermissionSelect from './UserPermissionSelect'

export default {
  name: 'userPermission',
  components: {
    appModalTemplate: ModalTemplate,
    appUserPermissionSelect: UserPermissionSelect
  },
  data() {
    return {
      showModalTrigger: false,
      localSelectedUserPermissions: [],
      permissions: {}
    }
  },

  computed: {
    ...mapGetters(['allPermissions', 'fetchingAllPermissions', 'fetchingAllRoles', 'selectedUserPermissions', 'permissionsByUserId'])
  },
  watch: {
    selectedUserPermissions(newVal) {
      this.localSelectedUserPermissions = newVal
    },
    permissionsByUserId(newVal) {
      this.localSelectedUserPermissions = this.simplifyPermissions(newVal)
    },
    showModalTrigger() {
      this.localSelectedUserPermissions = this.simplifyPermissions(this.selectedUserPermissions)
    }
  },

  methods: {
    ...mapActions(['setSelectedUserPermissions']),
    simplifyPermissions(permissions) {
      const simplePermissions = permissions.map((p) => ({
        permission_name: p.permission_name,
        resource_server_identifier: p.resource_server_identifier
      }))
      return simplePermissions
    },
    showPermissionsModal() {
      if (!this.fetchingAllPermissions && !this.fetchingAllRoles && this.allPermissions.length) {
        this.showModalTrigger = !this.showModalTrigger
      }
    },
    onSetUserPermissions() {
      this.setSelectedUserPermissions(this.localSelectedUserPermissions)
      this.closeModal()
    },
    closeModal() {
      this.$refs.permissionModal.closeModal()
    }
  }
}
</script>

<style scoped></style>
