<template>
  <app-user-template>
    <template slot="title">{{ setTitle() }}</template>
    <template slot="content">
      <b-message v-if="success.hasSuccess" :type="getMessageType">
        <p v-show="success.hasSuccess && !isEdition" class="is-size-6">Usuario creado con exito</p>
        <p v-show="isEdition" class="is-size-6">Usuario editado con exito</p>
      </b-message>

      <app-user-form
        @on-form-success="showSuccessMessages"
        @on-form-error="showErrorMessages"
        @on-form-processing="clearMessages"
      ></app-user-form>
    </template>
  </app-user-template>
</template>

<script>
import CommonTemplate from '@/views/templates/CommonTemplate'
import { mapActions, mapGetters } from 'vuex'
import UserForm from './UserForm'

export default {
  name: 'userCreate',
  components: {
    appUserTemplate: CommonTemplate,
    appUserForm: UserForm
  },
  data() {
    return {
      error: { hasError: false, invalidFormError: false },
      success: { hasSuccess: false }
    }
  },
  computed: {
    ...mapGetters(['duplicatedUser', 'organizations', 'getRespError']),
    getMessageType() {
      if (this.error.hasError) {
        return 'is-danger'
      } else {
        return 'is-success'
      }
    },
    isEdition() {
      return this.$route.params.hasOwnProperty('id')
    }
  },
  methods: {
    ...mapActions(['getOrganizations']),
    clearMessages({ ...error }, success = { hasSuccess: false }) {
      this.error = error
      this.success = { ...success }
    },
    setTitle() {
      if (this.$route.params.id) {
        return 'Editar Usuario'
      } else {
        return 'Crear Nuevo Usuario'
      }
    },
    showSuccessMessages({ ...success }) {
      this.success = success
      this.$buefy.snackbar.open({
        message: `Se ha guardado correctamente`,
        type: 'is-success'
      })
      if (success.hasSuccess) {
        setTimeout(() => {
          window.location.replace(`${process.env.VUE_APP_BASE_URI}/users/list`)
        }, '1500')
      }
    },
    showErrorMessages({ ...error }, products = []) {
      this.error = error
      if (this.getRespError) {
        this.$buefy.snackbar.open({ message: this.getRespError, type: 'is-danger', duration: 7000 })
      } else if (this.duplicatedUser) {
        this.$buefy.snackbar.open({ message: `Este email ya forma parte del sistema`, type: 'is-danger' })
      } else if (products.length === 0) {
        this.$buefy.snackbar.open({
          message: `Error: La organización principal de este usuario no tiene aplicaciones habilitadas`,
          type: 'is-danger',
          duration: 4000
        })
      } else if (error.hasError && !error.invalidFormError) {
        this.$buefy.snackbar.open({ message: `¡Ups algo salio mal!`, type: 'is-danger' })
      }
    }
  }
}
</script>

<style scoped></style>
