<template>
  <div style="height: 60vh">
    <permission-search @on-text-enter="filterPermissions"></permission-search>

    <div style="display: flex; margin: 20px 25px; justify-content: space-evenly">
      <div v-for="role in Object.keys(allRoles)" style="display: flex; align-items: center">
        <input
          @change="handleSetUserRole"
          :value="role"
          type="checkbox"
          :id="role"
          :checked="getIsRoleSelected(role)"
          style="width: 20px; height: 20px"
        />
        <label style="margin-left: 10px; text-transform: capitalize">{{ role.replace(/([a-z0-9])([A-Z])/g, '$1 $2') }}</label>
      </div>
    </div>
    <div
      v-for="groupName in Object.keys(filteredPermissions)"
      style="display: grid; align-items: center; border-bottom: 1px solid grey; margin-bottom: 10px; padding-bottom: 10px"
    >
      <p style="text-transform: capitalize; margin-bottom: 10px; font-size: 18px">{{ groupName }}</p>
      <div>
        <b-checkbox
          v-for="(permission, key) in filteredPermissions[groupName]"
          :key="key"
          v-model="modelForSelectedUserPermissions"
          @input="$emit('update:selectedUserPermissions', modelForSelectedUserPermissions)"
          :native-value="permission"
          style="width: 32%"
          type="is-info"
        >
          {{ permission.permission_name }}
        </b-checkbox>
        <input
          @change="handleSetAllPermissions"
          :value="JSON.stringify(filteredPermissions[groupName])"
          type="checkbox"
          :id="groupName"
          :checked="getAreAllPermissionSelected(filteredPermissions[groupName])"
          style="width: 20px; height: 20px"
        />
        <label style="margin-left: 10px; text-transform: capitalize; vertical-align: text-bottom">All</label>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PermissionSearch from '@/common/widgets/search/PermissionSearch'

export default {
  name: 'userPermissionSelect',
  components: {
    'permission-search': PermissionSearch
  },
  props: {
    selectedUserPermissions: {
      type: Array
    }
  },
  data() {
    return {
      modelForSelectedUserPermissions: this.selectedUserPermissions,
      filteredPermissions: []
    }
  },
  mounted: {},
  watch: {
    selectedUserPermissions(newVal) {
      this.modelForSelectedUserPermissions = newVal
    }
  },
  computed: {
    ...mapGetters(['allPermissions', 'allRoles'])
  },
  mounted() {
    this.filterPermissions('')
  },
  methods: {
    filterPermissions({ textToSearch }) {
      if (textToSearch) {
        // Filter allPermissions based on textToSearch
        const filteredPermissions = this.allPermissions.filter((permission) => permission.permission_name.includes(textToSearch))
        const groupedFilteredPermissions = this.groupPermissions(filteredPermissions)
        this.filteredPermissions = groupedFilteredPermissions
      } else {
        // Reset filteredPermissions to all allPermissions
        const groupedPermissions = this.groupPermissions(this.allPermissions)

        this.filteredPermissions = groupedPermissions
      }
    },
    groupPermissions(permissions) {
      //groups the permissions by the permission_name prefix (before ":")
      const groupedPermissions = {}

      permissions.forEach((permission) => {
        const prefix = permission.permission_name.split(':')[0]

        if (!groupedPermissions[prefix]) {
          groupedPermissions[prefix] = []
        }

        groupedPermissions[prefix].push({
          permission_name: permission.permission_name,
          resource_server_identifier: permission.resource_server_identifier
        })
      })
      return groupedPermissions
    },
    getPermissionsWithServer(roles) {
      //returns the permissions in the complete form to be read propperly in auth0
      const permissions = this.allPermissions.filter((p) => roles.find((rp) => rp.permission_name === p.permission_name))

      return permissions
    },
    getIsRoleSelected(role) {
      //checks if the permissions of the given role exists in the selected array of permissions
      const roles = this.allRoles[role]

      const selectedUserPermissions = new Set(this.modelForSelectedUserPermissions.map(JSON.stringify))

      const rolePermissionsWithServer = this.getPermissionsWithServer(roles)

      const isRoleSelected = rolePermissionsWithServer.every((obj) => selectedUserPermissions.has(JSON.stringify(obj)))

      return isRoleSelected
    },
    handleSetUserRole(e) {
      const roles = this.allRoles[e.target.value]

      const isRoleSelected = this.getIsRoleSelected(e.target.value)

      const rolePermissionsWithServer = this.getPermissionsWithServer(roles)

      this.handleSetPermissions(!isRoleSelected, rolePermissionsWithServer)
    },
    getAreAllPermissionSelected(permissions) {
      //checks if the given permissions exists in the array of selected permissions
      const selectedUserPermissions = new Set(this.modelForSelectedUserPermissions.map(JSON.stringify))

      const areAllPermissionsSelected = permissions.every((obj) => selectedUserPermissions.has(JSON.stringify(obj)))

      return areAllPermissionsSelected
    },

    handleSetAllPermissions(e) {
      const permissions = JSON.parse(e.target.value)

      const arePermissionsSelected = this.getAreAllPermissionSelected(permissions)

      this.handleSetPermissions(!arePermissionsSelected, permissions)
    },
    handleSetPermissions(variable, permissions) {
      // sets or removes the given permissions in the array of permissions
      if (variable) {
        let mergedPermissions = Array.from(new Set([...this.modelForSelectedUserPermissions, ...permissions].map(JSON.stringify))).map(
          JSON.parse
        )
        this.$emit('update:selectedUserPermissions', mergedPermissions)
      } else {
        const removeRole = this.modelForSelectedUserPermissions.filter(
          (p) => !permissions.some((r) => p.permission_name === r.permission_name)
        )
        this.$emit('update:selectedUserPermissions', removeRole)
      }
    }
  }
}
</script>
