<template>
  <section>
    <form id="userCreate" novalidate @submit.prevent>
      <b-tabs
        ><b-tab-item label="Información">
          <b-field position="is-right" grouped>
            <b-checkbox :true-value="1" :false-value="0" v-model="onlyPlanner" type="is-accent" size="is-small">Solo Planner</b-checkbox>
          </b-field>
          <b-field
            :message="errors.first('organization')"
            :type="errors.has('organization') ? 'is-danger' : ''"
            label="Organización principal"
          >
            <b-autocomplete
              v-validate="'required'"
              v-model="organizationName"
              :keep-first="keepFirst"
              :open-on-focus="openOnFocus"
              :data="filteredOrganizations"
              icon="search"
              placeholder="Buscar organización..."
              field="name"
              name="organization"
              @select="(option) => (selectedOrganization = option)"
            >
              <template slot="empty">No hay resultados</template>
            </b-autocomplete>
          </b-field>
          <br />
          <b-field grouped position="is-right">
            <p class="control">
              <a class="button is-outlined is-rounded is-small" @click="prepareMoreOrganizations()">
                <span class="icon">
                  <i class="fas fa-plus"></i>
                </span>
                <span>Enlazar otras Organizaciones</span>
              </a>
            </p>
          </b-field>
          <b-field v-if="showMoreOrganizationsInput || moreOrganizations.length" label="Elige otras organizaciones">
            <b-taginput
              v-model="moreOrganizations"
              :data="allOrganizations"
              autocomplete
              field="name"
              icon="sitemap"
              placeholder="Buscar organizaciones"
              type="is-dark"
              @typing="getFilteredOrganizations"
            ></b-taginput>
          </b-field>
          <b-field grouped>
            <b-field
              :type="errors.has('email') ? 'is-danger' : ''"
              :message="errors.first('email')"
              label="Correo Electrónico"
              class="has-text-light"
              expanded
            >
              <b-input
                v-validate="'required|email'"
                v-model="email"
                icon="envelope"
                name="email"
                placeholder="Ingrese un correo electrónico"
              ></b-input>
            </b-field>
            <b-field
              :type="errors.has('username') ? 'is-danger' : ''"
              :message="errors.first('username')"
              label="Username"
              class="has-text-light"
              expanded
            >
              <b-input
                v-validate="{ required: false, regex: /^[a-zA-Z0-9_@.-]+([a-zA-Z0-9_]+)*$/ }"
                v-model="username"
                icon="at"
                name="username"
                placeholder="Ingrese un Username"
              ></b-input>
            </b-field>
          </b-field>
          <b-field :type="errors.has('name') ? 'is-danger' : ''" :message="errors.first('name')" label="Nombre" class="has-text-light">
            <b-input v-validate="'required'" v-model="name" icon="user" name="name" placeholder="Ingrese un nombre"></b-input>
          </b-field>
          <br v-if="isEdition" />
          <b-field v-if="isEdition" grouped position="is-right">
            <p class="control">
              <a class="button is-outlined is-rounded is-small" @click="showPasswordInput = !showPasswordInput">
                <span>Resetear Password</span>
              </a>
            </p>
          </b-field>
          <b-field
            v-if="!isEdition || showPasswordInput"
            :type="errors.has('password') ? 'is-danger' : ''"
            :message="errors.first('password')"
            label="Contraseña"
            class="has-text-light"
          >
            <b-input
              v-model="password"
              name="password"
              icon="lock"
              autocomplete="off"
              placeholder="Ingrese una contraseña"
              type="password"
              password-reveal
            ></b-input>
          </b-field>
          <b-field label="Lenguaje">
            <b-select v-model="selectedLanguage" placeholder="Selecciona un lenguaje" required expanded icon="language">
              <option v-for="(language, index) in languages" :key="index" :value="language.shortHand">{{ language.name }}</option>
            </b-select>
          </b-field>
          <b-field class="file">
            <b-upload v-model="image" name="image" placeholder="Imagen de Usuario" expanded>
              <a class="button is-success">
                <b-icon icon="image"></b-icon>
                <span>Agregar Avatar</span>
              </a>
            </b-upload>
            <span v-if="image && image.length" class="file-name">{{ image[0].name }}</span>
          </b-field></b-tab-item
        ><b-tab-item label="Permisos">
          <app-user-permission-button></app-user-permission-button> <app-user-permissions-table></app-user-permissions-table> </b-tab-item
      ></b-tabs>
      <hr />
      <b-field grouped position="is-right">
        <p class="control">
          <router-link :to="{ name: 'user' }" class="button">
            <span>Cancelar</span>
          </router-link>
        </p>
        <p class="control">
          <a :class="{ 'is-loading': showLoader || this.requestUser }" class="button is-accent" @click="onUserCreate">
            <span>Guardar</span>
          </a>
        </p>
      </b-field>
    </form>
    <b-loading :is-full-page="true" :active.sync="this.requestUser" :can-cancel="true"></b-loading>
  </section>
</template>

<script>
import { formMixin } from '@/mixins/formMixin'
import { mapActions, mapGetters } from 'vuex'
import UserPermissionButton from './UserPermissionButton'
import PermissionsTable from './PermissionsTable.vue'

export default {
  name: 'userForm',
  components: {
    appUserPermissionButton: UserPermissionButton,
    appUserPermissionsTable: PermissionsTable
  },
  mixins: [formMixin],
  data() {
    return {
      languages: [],
      allOrganizations: [],
      moreOrganizations: [],
      organizationName: '',
      keepFirst: true,
      openOnFocus: true,
      email: '',
      selectedOrganization: null,
      selectedLanguage: 'es',
      password: '',
      name: '',
      username: '',
      onlyPlanner: 0,
      image: [],
      showPasswordInput: false,
      showMoreOrganizationsInput: false,
      userPermissionData: {},
      userRolesData: {}
    }
  },
  computed: {
    ...mapGetters([
      'showLoader',
      'organization',
      'organizations',
      'organizationUser',
      'requestOrganizations',
      'allPermissions',
      'permissionsByUserId',
      'allRoles',
      'selectedUserPermissions',
      'requestUser'
    ]),
    filteredOrganizations() {
      return this.organizations.filter(
        (organization) =>
          organization.name.toString().toLowerCase().includes(this.organizationName.toLowerCase()) && organization.enabled === 1
      )
    },
    isEdition() {
      return this.$route.params.hasOwnProperty('id')
    },
    linkedOrganizationsById() {
      if (this.moreOrganizations.length > 0) {
        return this.moreOrganizations.map((organization) => organization.id)
      }
      return []
    }
  },
  watch: {
    permissionsByUserId(newVal) {
      this.setSelectedUserPermissions(newVal)
    }
  },
  created() {
    this.getAllPermissions().then(() => {
      this.userPermissionData = { ...this.allPermissions }
    })
    this.getAllRoles().then(() => {
      this.userRolesData = { ...this.allRoles }
    })
    if (this.$route.params.hasOwnProperty('id')) {
      this.getPermissionsByUserId(this.$route.params.id).then(() => {
        this.userPermissionData = { ...this.allPermissions }
      })
    }
  },
  mounted() {
    this.setSelectedUserPermissions([])

    this.getOrganizations({ page: 1, size: 0 }).then(() => {
      this.allOrganizations = this.organizations.map((organization) => {
        return { ...organization }
      })
    })
    if (this.$route.params.id) {
      this.getUserById(this.$route.params.id).then(() => {
        this.prepareUserDataForEdition()
      })
    }
    setTimeout(() => {
      this.languages = [
        { id: 1, name: 'Español', shortHand: 'es' },
        { id: 2, name: 'Inglés', shortHand: 'en' },
        { id: 3, name: 'Portugúes', shortHand: 'pt' }
      ]
    }, 1000)
  },

  methods: {
    ...mapActions([
      'getOrganizations',
      'getUserById',
      'createUser',
      'getAllPermissions',
      'getPermissionsByUserId',
      'getAllRoles',
      'setSelectedUserPermissions'
    ]),
    getFilteredOrganizations(text) {
      this.allOrganizations = this.organizations.filter((option) => {
        return option.name.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0 && option.enabled === 1
      })
    },
    noWhiteSpaces(string) {
      let regex = /^(?!\s*$).+/gm
      return !regex.test(string)
    },

    onUserCreate() {
      let user

      user = {
        organizationId: this.selectedOrganization.id,
        organizations: this.linkedOrganizationsById,
        name: this.name,
        email: this.email,
        username: this.username,
        password: this.password.trim(),
        onlyPlanner: this.onlyPlanner,
        lang: this.selectedLanguage,
        image: this.image[0]
      }

      let permissionsToRemove = []
      let permissionsToAdd = []

      if (this.$route.params.hasOwnProperty('id')) {
        permissionsToRemove = this.permissionsByUserId.filter(
          ({ permission_name: pn1 }) => !this.selectedUserPermissions.some(({ permission_name: pn2 }) => pn2 === pn1)
        )
        permissionsToAdd = this.selectedUserPermissions.filter(
          ({ permission_name: pn1 }) => !this.permissionsByUserId.some(({ permission_name: pn2 }) => pn2 === pn1)
        )

        user = { ...user, permissionsToRemove, permissionsToAdd }
      } else {
        user = { ...user, permissions: this.selectedUserPermissions }
      }

      if (permissionsToRemove.length >= 100 || permissionsToAdd.length >= 100) {
        this.$buefy.snackbar.open({
          message: `Se han seleccionado mas de 100 permisos para agregar/remover, intenta seleccionar menos.(Limitacion de Auth0)`,
          type: 'is-warning'
        })
      } else {
        this.validateBeforeSubmit([this.createUser], {
          isEdition: this.$route.params.hasOwnProperty('id'),
          serverUserId: this.$route.params.hasOwnProperty('id') ? this.organizationUser.user_metadata.internal_user_id : null,
          auth0UserId: this.organizationUser.user_id,
          user
        })
      }
    },
    getOrgNameById(id) {
      return this.allOrganizations.find((organization) => organization.id === id)
    },
    prepareUserDataForEdition() {
      this.email = this.organizationUser.email
      this.name = this.organizationUser.name
      this.username = this.organizationUser.username
      this.organizationName = this.organizationUser.organization.name
      this.selectedOrganization = { ...this.organizationUser.organization }
      this.moreOrganizations = [...this.organizationUser.organizations]
      this.allOrganizations = [...this.organizationUser.organizations]
      this.selectedLanguage = this.organizationUser.user_metadata.lang
      this.onlyPlanner = this.organizationUser.onlyPlanner
    },
    prepareMoreOrganizations() {
      this.showMoreOrganizationsInput = !this.showMoreOrganizationsInput
    }
  }
}
</script>

<style scoped lang="scss"></style>
