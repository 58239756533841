var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column is-narrow-mobile" },
    [
      _c(
        "b-table",
        {
          attrs: {
            data: _vm.selectedUserPermissions,
            narrowed: "",
            striped: ""
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(props) {
                return [
                  _c(
                    "b-table-column",
                    {
                      attrs: {
                        field: "permissionName",
                        label: "Nombre del Permiso",
                        centered: ""
                      }
                    },
                    [_vm._v(_vm._s(props.row.permission_name))]
                  ),
                  _c(
                    "b-table-column",
                    {
                      attrs: {
                        field: "permissionName",
                        label: "Server",
                        centered: ""
                      }
                    },
                    [_vm._v(_vm._s(props.row.resource_server_identifier))]
                  ),
                  _c(
                    "b-table-column",
                    {
                      attrs: {
                        field: "actions",
                        label: "Acciones",
                        centered: ""
                      }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "has-text-danger",
                          on: {
                            click: function($event) {
                              return _vm.showConfirmationModal(
                                props.row.permission_name,
                                true
                              )
                            }
                          }
                        },
                        [
                          _c(
                            "b-tooltip",
                            {
                              attrs: {
                                label: _vm.$t("tables.remove"),
                                position: "is-top",
                                type: "is-danger",
                                size: "is-small",
                                animated: "",
                                square: ""
                              }
                            },
                            [
                              _c("b-icon", {
                                staticClass: "has-padding",
                                attrs: {
                                  pack: "fas",
                                  icon: "trash",
                                  size: "is-small"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ]
              }
            }
          ])
        },
        [
          _c("template", { slot: "empty" }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.selectedUserPermissions.length,
                          expression: "!selectedUserPermissions.length"
                        }
                      ]
                    },
                    [
                      _c("b-icon", {
                        attrs: {
                          pack: "fas",
                          icon: "exclamation",
                          size: "is-large"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.selectedUserPermissions.length,
                          expression: "!selectedUserPermissions.length"
                        }
                      ]
                    },
                    [_vm._v(_vm._s(_vm.$t("warnings.unsetPermissions")))]
                  )
                ]
              )
            ])
          ])
        ],
        2
      ),
      _c(
        "b-modal",
        {
          staticClass: "has-modal-card",
          attrs: {
            active: _vm.confirmationModalActive,
            canCancel: ["x", "outside"]
          },
          on: {
            "update:active": function($event) {
              _vm.confirmationModalActive = $event
            }
          }
        },
        [
          _c(
            "app-modal-template",
            {
              ref: "confirmationModal",
              attrs: { "has-closable-button": true }
            },
            [
              _c("template", { slot: "title" }, [
                _c("p", { staticClass: "modal-card-title" }, [
                  _vm._v("Quitar Permiso")
                ])
              ]),
              _c("template", { slot: "content" }, [
                _c("p", { staticClass: "modal-card-title" }, [
                  _vm._v("¿Esta seguro que quiere quitar este permiso?")
                ])
              ]),
              _c("template", { slot: "buttons" }, [
                _c(
                  "button",
                  {
                    staticClass: "button is-accent",
                    on: { click: _vm.onRemovePermission }
                  },
                  [
                    _c("span", { staticClass: "icon" }, [
                      _c("i", { staticClass: "fa fa-trash" })
                    ]),
                    _c("span", [_vm._v("Quitar")])
                  ]
                )
              ])
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }