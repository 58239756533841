<template>
  <b-field>
    <b-input
      ref="searchWidget"
      v-model="queryText"
      placeholder="Introduzca su busqueda..."
      rounded
      icon-pack="fas"
      size="is-small"
      icon="search"
      autofocus
      @keyup.native="onTextEnter"
    ></b-input>
  </b-field>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'inputSearch',
  props: {
    hasFilter: {
      type: Boolean,
      default: () => {
        return true
      }
    }
  },
  data() {
    return {
      queryText: '',
      orgType: '0'
    }
  },
  computed: {
    hasRequestableType() {
      console.log('hasRequestableType -> this.orgType', this.orgType)
      return parseInt(this.orgType) === 1 || parseInt(this.orgType) === 2
    }
  },
  created() {},
  mounted() {
    this.$refs.searchWidget.focus() // Triggers autofocus on search widget
  },
  methods: {
    ...mapActions(['getUsers']),
    onTextEnter() {
      this.$emit('on-text-enter', { textToSearch: this.queryText })
    }
  }
}
</script>

<style scoped></style>
