var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-field",
    [
      _c("b-input", {
        ref: "searchWidget",
        attrs: {
          placeholder: "Introduzca su busqueda...",
          rounded: "",
          "icon-pack": "fas",
          size: "is-small",
          icon: "search",
          autofocus: ""
        },
        nativeOn: {
          keyup: function($event) {
            return _vm.onTextEnter.apply(null, arguments)
          }
        },
        model: {
          value: _vm.queryText,
          callback: function($$v) {
            _vm.queryText = $$v
          },
          expression: "queryText"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }