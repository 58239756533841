var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "60vh" } },
    [
      _c("permission-search", {
        on: { "on-text-enter": _vm.filterPermissions }
      }),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            margin: "20px 25px",
            "justify-content": "space-evenly"
          }
        },
        _vm._l(Object.keys(_vm.allRoles), function(role) {
          return _c(
            "div",
            { staticStyle: { display: "flex", "align-items": "center" } },
            [
              _c("input", {
                staticStyle: { width: "20px", height: "20px" },
                attrs: { type: "checkbox", id: role },
                domProps: { value: role, checked: _vm.getIsRoleSelected(role) },
                on: { change: _vm.handleSetUserRole }
              }),
              _c(
                "label",
                {
                  staticStyle: {
                    "margin-left": "10px",
                    "text-transform": "capitalize"
                  }
                },
                [_vm._v(_vm._s(role.replace(/([a-z0-9])([A-Z])/g, "$1 $2")))]
              )
            ]
          )
        }),
        0
      ),
      _vm._l(Object.keys(_vm.filteredPermissions), function(groupName) {
        return _c(
          "div",
          {
            staticStyle: {
              display: "grid",
              "align-items": "center",
              "border-bottom": "1px solid grey",
              "margin-bottom": "10px",
              "padding-bottom": "10px"
            }
          },
          [
            _c(
              "p",
              {
                staticStyle: {
                  "text-transform": "capitalize",
                  "margin-bottom": "10px",
                  "font-size": "18px"
                }
              },
              [_vm._v(_vm._s(groupName))]
            ),
            _c(
              "div",
              [
                _vm._l(_vm.filteredPermissions[groupName], function(
                  permission,
                  key
                ) {
                  return _c(
                    "b-checkbox",
                    {
                      key: key,
                      staticStyle: { width: "32%" },
                      attrs: { "native-value": permission, type: "is-info" },
                      on: {
                        input: function($event) {
                          return _vm.$emit(
                            "update:selectedUserPermissions",
                            _vm.modelForSelectedUserPermissions
                          )
                        }
                      },
                      model: {
                        value: _vm.modelForSelectedUserPermissions,
                        callback: function($$v) {
                          _vm.modelForSelectedUserPermissions = $$v
                        },
                        expression: "modelForSelectedUserPermissions"
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(permission.permission_name) +
                          "\n      "
                      )
                    ]
                  )
                }),
                _c("input", {
                  staticStyle: { width: "20px", height: "20px" },
                  attrs: { type: "checkbox", id: groupName },
                  domProps: {
                    value: JSON.stringify(_vm.filteredPermissions[groupName]),
                    checked: _vm.getAreAllPermissionSelected(
                      _vm.filteredPermissions[groupName]
                    )
                  },
                  on: { change: _vm.handleSetAllPermissions }
                }),
                _c(
                  "label",
                  {
                    staticStyle: {
                      "margin-left": "10px",
                      "text-transform": "capitalize",
                      "vertical-align": "text-bottom"
                    }
                  },
                  [_vm._v("All")]
                )
              ],
              2
            )
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }