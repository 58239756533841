var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column is-narrow-mobile" },
    [
      _c("div", { staticClass: "card" }, [
        _c(
          "header",
          { staticClass: "card-header is-flex is-flex--space-between" },
          [
            _c("div", [
              _c(
                "p",
                { staticClass: "card-header-title is-size-5 has-text-grey" },
                [_vm._v(_vm._s(_vm.$t("users.title")))]
              )
            ]),
            _c("div", { staticClass: "is-flex is-flex--center" }, [
              _c(
                "div",
                { staticClass: "is-flex is-flex--space-between" },
                [
                  _c("app-input-search", {
                    attrs: { "has-filter": false },
                    on: { "on-text-enter": _vm.searchFilteredUsers }
                  })
                ],
                1
              ),
              _c(
                "a",
                {
                  staticClass: "card-header-icon",
                  attrs: { "aria-label": "more options" }
                },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "button is-accent is-small",
                      attrs: { to: { name: "userCreate" } }
                    },
                    [
                      _c("span", { staticClass: "icon" }, [
                        _c("i", { staticClass: "fas fa-plus" })
                      ]),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("forms.buttons.addUser")))
                      ])
                    ]
                  )
                ],
                1
              )
            ])
          ]
        ),
        _c(
          "div",
          { staticClass: "card-content" },
          [
            _c(
              "b-table",
              {
                attrs: {
                  loading: _vm.requestUsers,
                  total: _vm.totalUsers,
                  data: _vm.users.length ? _vm.users : [],
                  "per-page": _vm.usersPagination.perPage,
                  "backend-pagination": "",
                  paginated: "",
                  "pagination-size": "is-small",
                  narrowed: "",
                  striped: ""
                },
                on: { "page-change": _vm.triggerAsyncPageChange },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        _c(
                          "b-table-column",
                          {
                            attrs: {
                              field: "id",
                              label: "ID",
                              width: "40",
                              numeric: ""
                            }
                          },
                          [
                            _vm._v(_vm._s(props.row.id) + "\n            "),
                            _vm.copiedText === props.row.auth0UserId
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "has-text-info",
                                    on: {
                                      click: function($event) {
                                        return _vm.copyAuth0Id(
                                          props.row.auth0UserId
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          label: "copied!",
                                          position: "is-top",
                                          type: "is-info",
                                          size: "is-small",
                                          animated: "",
                                          square: ""
                                        }
                                      },
                                      [
                                        _c("b-icon", {
                                          staticClass: "has-padding",
                                          attrs: {
                                            pack: "fas",
                                            icon: "check",
                                            size: "is-small"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _c(
                                  "a",
                                  {
                                    staticClass: "has-text-info",
                                    on: {
                                      click: function($event) {
                                        return _vm.copyAuth0Id(
                                          props.row.auth0UserId
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          label: props.row.auth0UserId,
                                          position: "is-top",
                                          type: "is-info",
                                          size: "is-small",
                                          animated: "",
                                          square: ""
                                        }
                                      },
                                      [
                                        _c("b-icon", {
                                          staticClass: "has-padding",
                                          attrs: {
                                            pack: "fas",
                                            icon: "user",
                                            size: "is-small"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                          ]
                        ),
                        _c(
                          "b-table-column",
                          {
                            attrs: {
                              field: "image",
                              label: "Avatar",
                              width: "48",
                              centered: ""
                            }
                          },
                          [
                            props.row.image
                              ? _c(
                                  "figure",
                                  { staticClass: "is-flex is-flex--center" },
                                  [
                                    _c("p", { staticClass: "image is-32x32" }, [
                                      _c("img", {
                                        staticClass: "is-rounded",
                                        attrs: {
                                          src: _vm.getUrlImage(props.row.image)
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _c("span", { staticClass: "icon is-medium" }, [
                                  _c("span", { staticClass: "fa-stack" }, [
                                    _c("i", {
                                      staticClass: "fas fa-circle fa-stack-2x"
                                    }),
                                    _c("i", {
                                      staticClass:
                                        "fas fa-user fa-stack-1x fa-inverse"
                                    })
                                  ])
                                ])
                          ]
                        ),
                        _c(
                          "b-table-column",
                          {
                            attrs: {
                              field: "name",
                              label: "Organización",
                              width: "100",
                              centered: ""
                            }
                          },
                          [
                            _c("p", [
                              _c(
                                "span",
                                {
                                  staticClass: "tag is-info",
                                  class: props.row.organization
                                    ? "is-info"
                                    : "is-danger"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      props.row.organization
                                        .organization_name || "Sin Organización"
                                    )
                                  )
                                ]
                              )
                            ]),
                            props.row.organizations.length > 0
                              ? _vm._l(
                                  _vm.getTrimmedOrganizations(
                                    props.row.organization,
                                    props.row.organizations
                                  ),
                                  function(linkedOrganization) {
                                    return _c(
                                      "p",
                                      { key: linkedOrganization.id },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "tag is-grey" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                linkedOrganization.organization_name
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  }
                                )
                              : _vm._e()
                          ],
                          2
                        ),
                        _c(
                          "b-table-column",
                          {
                            attrs: {
                              field: "email",
                              label: "Username",
                              width: "100",
                              centered: ""
                            }
                          },
                          [_vm._v(_vm._s(props.row.username))]
                        ),
                        _c(
                          "b-table-column",
                          {
                            attrs: {
                              field: "created",
                              label: "Creación",
                              centered: ""
                            }
                          },
                          [
                            _c("span", { staticClass: "tag is-info" }, [
                              _vm._v(
                                _vm._s(
                                  new Date(
                                    props.row.created
                                  ).toLocaleDateString()
                                )
                              )
                            ])
                          ]
                        ),
                        _c(
                          "b-table-column",
                          {
                            attrs: {
                              field: "status",
                              label: "Estado",
                              centered: ""
                            }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "tag is-success",
                                class: props.row.enabled
                                  ? "is-success"
                                  : "is-danger"
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    props.row.enabled ? "Activo" : "Inactivo"
                                  )
                                )
                              ]
                            )
                          ]
                        ),
                        !!props.row.enabled
                          ? _c(
                              "b-table-column",
                              {
                                attrs: {
                                  field: "actions",
                                  label: "Acciones",
                                  centered: ""
                                }
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "has-text-info",
                                    on: {
                                      click: function($event) {
                                        return _vm.goToUserEdition(
                                          props.row.auth0UserId
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          label: _vm.$t("tables.edit"),
                                          position: "is-top",
                                          type: "is-info",
                                          size: "is-small",
                                          animated: "",
                                          square: ""
                                        }
                                      },
                                      [
                                        _c("b-icon", {
                                          staticClass: "has-padding",
                                          attrs: {
                                            pack: "fas",
                                            icon: "edit",
                                            size: "is-small"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "a",
                                  {
                                    staticClass: "has-text-danger",
                                    on: {
                                      click: function($event) {
                                        return _vm.showConfirmationModal(
                                          props.row.id,
                                          true
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          label: _vm.$t("tables.remove"),
                                          position: "is-top",
                                          type: "is-danger",
                                          size: "is-small",
                                          animated: "",
                                          square: ""
                                        }
                                      },
                                      [
                                        _c("b-icon", {
                                          staticClass: "has-padding",
                                          attrs: {
                                            pack: "fas",
                                            icon: "trash",
                                            size: "is-small"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          : _c(
                              "b-table-column",
                              {
                                attrs: {
                                  field: "actions",
                                  label: "Acciones",
                                  centered: ""
                                }
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "has-text-success",
                                    on: {
                                      click: function($event) {
                                        return _vm.showConfirmationModal(
                                          props.row.id,
                                          false
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          label: _vm.$t("tables.add"),
                                          position: "is-top",
                                          type: "is-success",
                                          size: "is-small",
                                          animated: "",
                                          square: ""
                                        }
                                      },
                                      [
                                        _c("b-icon", {
                                          staticClass: "has-padding",
                                          attrs: {
                                            pack: "fas",
                                            icon: "power-off",
                                            size: "is-small"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                      ]
                    }
                  }
                ])
              },
              [
                _c("template", { slot: "empty" }, [
                  _c("section", { staticClass: "section" }, [
                    _c(
                      "div",
                      {
                        staticClass: "content has-text-grey has-text-centered"
                      },
                      [
                        _c(
                          "p",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  !_vm.shortQuerySearchError &&
                                  !_vm.requestUserError &&
                                  !_vm.requestUsers,
                                expression:
                                  "!shortQuerySearchError && !requestUserError && !requestUsers"
                              }
                            ]
                          },
                          [
                            _c("b-icon", {
                              attrs: {
                                pack: "fas",
                                icon: "exclamation",
                                size: "is-large"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "p",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  !_vm.shortQuerySearchError &&
                                  !_vm.requestUserError &&
                                  !_vm.requestUsers,
                                expression:
                                  "!shortQuerySearchError && !requestUserError && !requestUsers"
                              }
                            ]
                          },
                          [_vm._v(_vm._s(_vm.$t("warnings.accountNoData")))]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "content has-text-grey has-text-centered"
                      },
                      [
                        _c(
                          "p",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.shortQuerySearchError,
                                expression: "shortQuerySearchError"
                              }
                            ]
                          },
                          [
                            _c("b-icon", {
                              attrs: {
                                pack: "fas",
                                icon: "exclamation",
                                size: "is-large"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "p",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.shortQuerySearchError,
                                expression: "shortQuerySearchError"
                              }
                            ]
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("warnings.shortQuerySearchError"))
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.requestUserError,
                            expression: "requestUserError"
                          }
                        ],
                        staticClass: "content has-text-danger has-text-centered"
                      },
                      [
                        _c("p", [
                          _c("span", { staticClass: "icon is-large" }, [
                            _c("i", { staticClass: "far fa-frown fa-3x" })
                          ])
                        ]),
                        _c("p", [_vm._v(_vm._s(_vm.$t("errors.requestError")))])
                      ]
                    )
                  ])
                ])
              ],
              2
            )
          ],
          1
        )
      ]),
      _c(
        "b-modal",
        {
          staticClass: "has-modal-card",
          attrs: {
            active: _vm.confirmationModalActive,
            canCancel: ["x", "outside"]
          },
          on: {
            "update:active": function($event) {
              _vm.confirmationModalActive = $event
            }
          }
        },
        [
          _c(
            "app-modal-template",
            {
              ref: "confirmationModal",
              attrs: { "has-closable-button": true }
            },
            [
              _c("template", { slot: "title" }, [
                _c("p", { staticClass: "modal-card-title" }, [
                  _vm._v(
                    _vm._s(_vm.removeOperation ? "Borrar" : "Habilitar") +
                      " usuario"
                  )
                ])
              ]),
              _c("template", { slot: "content" }, [
                _c("p", { staticClass: "modal-card-title" }, [
                  _vm._v(
                    "¿Esta seguro que quiere " +
                      _vm._s(_vm.removeOperation ? "eliminar" : "habilitar") +
                      " este usuario?"
                  )
                ])
              ]),
              _c("template", { slot: "buttons" }, [
                _vm.removeOperation
                  ? _c(
                      "button",
                      {
                        staticClass: "button is-accent",
                        class: { "is-loading": _vm.showLoader },
                        on: { click: _vm.onRemoveUser }
                      },
                      [
                        _c("span", { staticClass: "icon" }, [
                          _c("i", { staticClass: "fa fa-trash" })
                        ]),
                        _c("span", [_vm._v("Borrar")])
                      ]
                    )
                  : _c(
                      "button",
                      {
                        staticClass: "button is-accent",
                        class: { "is-loading": _vm.showLoader },
                        on: { click: _vm.onAddUser }
                      },
                      [
                        _c("span", { staticClass: "icon" }, [
                          _c("i", { staticClass: "fa fa-power-off" })
                        ]),
                        _c("span", [_vm._v("Habilitar")])
                      ]
                    )
              ])
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }