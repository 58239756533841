var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-field", { attrs: { grouped: "", position: "is-right" } }, [
        _c("p", { staticClass: "control" }, [
          _c(
            "a",
            {
              staticClass: "button is-rounded is-focused is-small",
              class: [
                {
                  "is-loading":
                    _vm.fetchingAllPermissions || _vm.fetchingAllRoles
                },
                "is-accent"
              ],
              on: {
                click: function($event) {
                  return _vm.showPermissionsModal()
                }
              }
            },
            [
              _c("span", { staticClass: "icon" }, [
                _c("i", { staticClass: "fas fa-key" })
              ]),
              !!_vm.allPermissions.length
                ? _c("span", [_vm._v("Establecer Permisos")])
                : _c("span", [_vm._v("No hay Permisos")])
            ]
          )
        ])
      ]),
      _c(
        "b-modal",
        {
          staticClass: "has-modal-card",
          attrs: { active: _vm.showModalTrigger, canCancel: ["x", "outside"] },
          on: {
            "update:active": function($event) {
              _vm.showModalTrigger = $event
            }
          }
        },
        [
          _c(
            "app-modal-template",
            {
              ref: "permissionModal",
              staticStyle: { width: "auto" },
              attrs: { "has-closable-button": true }
            },
            [
              _c("template", { slot: "title" }, [
                _c(
                  "p",
                  {
                    staticClass: "modal-card-title",
                    staticStyle: { "text-align": "center" }
                  },
                  [_vm._v("Permisos de Aplicaciones")]
                )
              ]),
              _c(
                "template",
                { slot: "content" },
                [
                  _c("app-user-permission-select", {
                    attrs: {
                      selectedUserPermissions: _vm.localSelectedUserPermissions
                    },
                    on: {
                      "update:selectedUserPermissions": function($event) {
                        _vm.localSelectedUserPermissions = $event
                      },
                      "update:selected-user-permissions": function($event) {
                        _vm.localSelectedUserPermissions = $event
                      }
                    }
                  })
                ],
                1
              ),
              _c("template", { slot: "buttons" }, [
                _c(
                  "button",
                  {
                    staticClass: "button is-accent",
                    on: { click: _vm.onSetUserPermissions }
                  },
                  [
                    _c("span", { staticClass: "icon" }, [
                      _c("i", { staticClass: "far fa-save" })
                    ]),
                    _c("span", [_vm._v("Guardar")])
                  ]
                )
              ])
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }