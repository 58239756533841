var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-user-template",
    [
      _c("template", { slot: "title" }, [_vm._v(_vm._s(_vm.setTitle()))]),
      _c(
        "template",
        { slot: "content" },
        [
          _vm.success.hasSuccess
            ? _c("b-message", { attrs: { type: _vm.getMessageType } }, [
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.success.hasSuccess && !_vm.isEdition,
                        expression: "success.hasSuccess && !isEdition"
                      }
                    ],
                    staticClass: "is-size-6"
                  },
                  [_vm._v("Usuario creado con exito")]
                ),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isEdition,
                        expression: "isEdition"
                      }
                    ],
                    staticClass: "is-size-6"
                  },
                  [_vm._v("Usuario editado con exito")]
                )
              ])
            : _vm._e(),
          _c("app-user-form", {
            on: {
              "on-form-success": _vm.showSuccessMessages,
              "on-form-error": _vm.showErrorMessages,
              "on-form-processing": _vm.clearMessages
            }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }