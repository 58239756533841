var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "form",
        {
          attrs: { id: "userCreate", novalidate: "" },
          on: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "b-tabs",
            [
              _c(
                "b-tab-item",
                { attrs: { label: "Información" } },
                [
                  _c(
                    "b-field",
                    { attrs: { position: "is-right", grouped: "" } },
                    [
                      _c(
                        "b-checkbox",
                        {
                          attrs: {
                            "true-value": 1,
                            "false-value": 0,
                            type: "is-accent",
                            size: "is-small"
                          },
                          model: {
                            value: _vm.onlyPlanner,
                            callback: function($$v) {
                              _vm.onlyPlanner = $$v
                            },
                            expression: "onlyPlanner"
                          }
                        },
                        [_vm._v("Solo Planner")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-field",
                    {
                      attrs: {
                        message: _vm.errors.first("organization"),
                        type: _vm.errors.has("organization") ? "is-danger" : "",
                        label: "Organización principal"
                      }
                    },
                    [
                      _c(
                        "b-autocomplete",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          attrs: {
                            "keep-first": _vm.keepFirst,
                            "open-on-focus": _vm.openOnFocus,
                            data: _vm.filteredOrganizations,
                            icon: "search",
                            placeholder: "Buscar organización...",
                            field: "name",
                            name: "organization"
                          },
                          on: {
                            select: function(option) {
                              return (_vm.selectedOrganization = option)
                            }
                          },
                          model: {
                            value: _vm.organizationName,
                            callback: function($$v) {
                              _vm.organizationName = $$v
                            },
                            expression: "organizationName"
                          }
                        },
                        [
                          _c("template", { slot: "empty" }, [
                            _vm._v("No hay resultados")
                          ])
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "b-field",
                    { attrs: { grouped: "", position: "is-right" } },
                    [
                      _c("p", { staticClass: "control" }, [
                        _c(
                          "a",
                          {
                            staticClass:
                              "button is-outlined is-rounded is-small",
                            on: {
                              click: function($event) {
                                return _vm.prepareMoreOrganizations()
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "icon" }, [
                              _c("i", { staticClass: "fas fa-plus" })
                            ]),
                            _c("span", [_vm._v("Enlazar otras Organizaciones")])
                          ]
                        )
                      ])
                    ]
                  ),
                  _vm.showMoreOrganizationsInput || _vm.moreOrganizations.length
                    ? _c(
                        "b-field",
                        { attrs: { label: "Elige otras organizaciones" } },
                        [
                          _c("b-taginput", {
                            attrs: {
                              data: _vm.allOrganizations,
                              autocomplete: "",
                              field: "name",
                              icon: "sitemap",
                              placeholder: "Buscar organizaciones",
                              type: "is-dark"
                            },
                            on: { typing: _vm.getFilteredOrganizations },
                            model: {
                              value: _vm.moreOrganizations,
                              callback: function($$v) {
                                _vm.moreOrganizations = $$v
                              },
                              expression: "moreOrganizations"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-field",
                    { attrs: { grouped: "" } },
                    [
                      _c(
                        "b-field",
                        {
                          staticClass: "has-text-light",
                          attrs: {
                            type: _vm.errors.has("email") ? "is-danger" : "",
                            message: _vm.errors.first("email"),
                            label: "Correo Electrónico",
                            expanded: ""
                          }
                        },
                        [
                          _c("b-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|email",
                                expression: "'required|email'"
                              }
                            ],
                            attrs: {
                              icon: "envelope",
                              name: "email",
                              placeholder: "Ingrese un correo electrónico"
                            },
                            model: {
                              value: _vm.email,
                              callback: function($$v) {
                                _vm.email = $$v
                              },
                              expression: "email"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-field",
                        {
                          staticClass: "has-text-light",
                          attrs: {
                            type: _vm.errors.has("username") ? "is-danger" : "",
                            message: _vm.errors.first("username"),
                            label: "Username",
                            expanded: ""
                          }
                        },
                        [
                          _c("b-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  required: false,
                                  regex: /^[a-zA-Z0-9_@.-]+([a-zA-Z0-9_]+)*$/
                                },
                                expression:
                                  "{ required: false, regex: /^[a-zA-Z0-9_@.-]+([a-zA-Z0-9_]+)*$/ }"
                              }
                            ],
                            attrs: {
                              icon: "at",
                              name: "username",
                              placeholder: "Ingrese un Username"
                            },
                            model: {
                              value: _vm.username,
                              callback: function($$v) {
                                _vm.username = $$v
                              },
                              expression: "username"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-field",
                    {
                      staticClass: "has-text-light",
                      attrs: {
                        type: _vm.errors.has("name") ? "is-danger" : "",
                        message: _vm.errors.first("name"),
                        label: "Nombre"
                      }
                    },
                    [
                      _c("b-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        attrs: {
                          icon: "user",
                          name: "name",
                          placeholder: "Ingrese un nombre"
                        },
                        model: {
                          value: _vm.name,
                          callback: function($$v) {
                            _vm.name = $$v
                          },
                          expression: "name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.isEdition ? _c("br") : _vm._e(),
                  _vm.isEdition
                    ? _c(
                        "b-field",
                        { attrs: { grouped: "", position: "is-right" } },
                        [
                          _c("p", { staticClass: "control" }, [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "button is-outlined is-rounded is-small",
                                on: {
                                  click: function($event) {
                                    _vm.showPasswordInput = !_vm.showPasswordInput
                                  }
                                }
                              },
                              [_c("span", [_vm._v("Resetear Password")])]
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  !_vm.isEdition || _vm.showPasswordInput
                    ? _c(
                        "b-field",
                        {
                          staticClass: "has-text-light",
                          attrs: {
                            type: _vm.errors.has("password") ? "is-danger" : "",
                            message: _vm.errors.first("password"),
                            label: "Contraseña"
                          }
                        },
                        [
                          _c("b-input", {
                            attrs: {
                              name: "password",
                              icon: "lock",
                              autocomplete: "off",
                              placeholder: "Ingrese una contraseña",
                              type: "password",
                              "password-reveal": ""
                            },
                            model: {
                              value: _vm.password,
                              callback: function($$v) {
                                _vm.password = $$v
                              },
                              expression: "password"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-field",
                    { attrs: { label: "Lenguaje" } },
                    [
                      _c(
                        "b-select",
                        {
                          attrs: {
                            placeholder: "Selecciona un lenguaje",
                            required: "",
                            expanded: "",
                            icon: "language"
                          },
                          model: {
                            value: _vm.selectedLanguage,
                            callback: function($$v) {
                              _vm.selectedLanguage = $$v
                            },
                            expression: "selectedLanguage"
                          }
                        },
                        _vm._l(_vm.languages, function(language, index) {
                          return _c(
                            "option",
                            {
                              key: index,
                              domProps: { value: language.shortHand }
                            },
                            [_vm._v(_vm._s(language.name))]
                          )
                        }),
                        0
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-field",
                    { staticClass: "file" },
                    [
                      _c(
                        "b-upload",
                        {
                          attrs: {
                            name: "image",
                            placeholder: "Imagen de Usuario",
                            expanded: ""
                          },
                          model: {
                            value: _vm.image,
                            callback: function($$v) {
                              _vm.image = $$v
                            },
                            expression: "image"
                          }
                        },
                        [
                          _c(
                            "a",
                            { staticClass: "button is-success" },
                            [
                              _c("b-icon", { attrs: { icon: "image" } }),
                              _c("span", [_vm._v("Agregar Avatar")])
                            ],
                            1
                          )
                        ]
                      ),
                      _vm.image && _vm.image.length
                        ? _c("span", { staticClass: "file-name" }, [
                            _vm._v(_vm._s(_vm.image[0].name))
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-tab-item",
                { attrs: { label: "Permisos" } },
                [
                  _c("app-user-permission-button"),
                  _c("app-user-permissions-table")
                ],
                1
              )
            ],
            1
          ),
          _c("hr"),
          _c("b-field", { attrs: { grouped: "", position: "is-right" } }, [
            _c(
              "p",
              { staticClass: "control" },
              [
                _c(
                  "router-link",
                  { staticClass: "button", attrs: { to: { name: "user" } } },
                  [_c("span", [_vm._v("Cancelar")])]
                )
              ],
              1
            ),
            _c("p", { staticClass: "control" }, [
              _c(
                "a",
                {
                  staticClass: "button is-accent",
                  class: { "is-loading": _vm.showLoader || this.requestUser },
                  on: { click: _vm.onUserCreate }
                },
                [_c("span", [_vm._v("Guardar")])]
              )
            ])
          ])
        ],
        1
      ),
      _c("b-loading", {
        attrs: {
          "is-full-page": true,
          active: this.requestUser,
          "can-cancel": true
        },
        on: {
          "update:active": function($event) {
            return _vm.$set(this, "requestUser", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }