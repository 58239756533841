<template>
  <div class="column is-narrow-mobile">
    <b-table :data="selectedUserPermissions" narrowed striped>
      <template slot-scope="props">
        <b-table-column field="permissionName" label="Nombre del Permiso" centered>{{ props.row.permission_name }}</b-table-column>
        <b-table-column field="permissionName" label="Server" centered>{{ props.row.resource_server_identifier }}</b-table-column>
        <b-table-column field="actions" label="Acciones" centered>
          <a class="has-text-danger" @click="showConfirmationModal(props.row.permission_name, true)">
            <b-tooltip :label="$t('tables.remove')" position="is-top" type="is-danger" size="is-small" animated square>
              <b-icon class="has-padding" pack="fas" icon="trash" size="is-small"></b-icon>
            </b-tooltip>
          </a>
        </b-table-column>
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p v-show="!selectedUserPermissions.length">
              <b-icon pack="fas" icon="exclamation" size="is-large"></b-icon>
            </p>
            <p v-show="!selectedUserPermissions.length">{{ $t('warnings.unsetPermissions') }}</p>
          </div>
          <!-- <div class="content has-text-grey has-text-centered">
                <p v-show="!shortQuerySearchError && !requestUserError && !requestUsers">
                  <b-icon pack="fas" icon="exclamation" size="is-large"></b-icon>
                </p>
                <p v-show="!shortQuerySearchError && !requestUserError && !requestUsers">{{ $t('warnings.accountNoData') }}</p>
              </div>
              <div v-show="requestUserError" class="content has-text-danger has-text-centered">
                <p>
                  <span class="icon is-large">
                    <i class="far fa-frown fa-3x"></i>
                  </span>
                </p>
                <p>{{ $t('errors.requestError') }}</p>
              </div> -->
        </section>
      </template>
    </b-table>

    <b-modal :active.sync="confirmationModalActive" :canCancel="['x', 'outside']" class="has-modal-card">
      <app-modal-template ref="confirmationModal" :has-closable-button="true">
        <template slot="title">
          <p class="modal-card-title">Quitar Permiso</p>
        </template>
        <template slot="content">
          <p class="modal-card-title">¿Esta seguro que quiere quitar este permiso?</p>
        </template>
        <template slot="buttons">
          <button class="button is-accent" @click="onRemovePermission">
            <span class="icon">
              <i class="fa fa-trash"></i>
            </span>
            <span>Quitar</span>
          </button>
        </template>
      </app-modal-template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ModalTemplate from '@/common/widgets/modal/ModalTemplate'

export default {
  name: 'permissionsTable',
  components: {
    appModalTemplate: ModalTemplate
  },
  data() {
    return {
      permissionName: null,
      confirmationModalActive: false
    }
  },
  computed: {
    ...mapGetters(['selectedUserPermissions'])
  },
  watch: {
    tokenValid(value) {
      if (!value) {
        this.$router.push({ name: 'login' })
      }
    }
  },
  created() {},
  methods: {
    ...mapActions(['setSelectedUserPermissions']),

    showConfirmationModal(permissionName) {
      this.confirmationModalActive = !this.confirmationModalActive
      this.permissionName = permissionName
    },
    onRemovePermission() {
      const path = this.$route.path

      if (this.confirmationModalActive) {
        const newList = this.selectedUserPermissions.filter((permission) => permission.permission_name !== this.permissionName)
        const simplePermissions = newList.map((p) => ({
          permission_name: p.permission_name,
          resource_server_identifier: p.resource_server_identifier
        }))
        this.setSelectedUserPermissions(simplePermissions)
      }
      this.closeModal()
    },
    closeModal() {
      this.$refs.confirmationModal.closeModal()
    }
  }
}
</script>
<style lang="scss" scoped></style>
